<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="stroke-black"
  >
    <circle cx="12" cy="7.5" r="4.5" />
    <path
      d="M21 21C21 17.2721 16.9706 14.25 12 14.25C7.02944 14.25 3 17.2721 3 21"
    />
  </svg>
</template>
